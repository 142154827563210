import React from "react";
import styled from 'styled-components';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from 'gatsby-image';
import { SinglePostContainer } from '../templates/blog-post';
import BlogFooter from '../components/blog-footer';

const AboutContainer = styled(SinglePostContainer)`
  min-height: 70vh;
`;

class AboutPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const group = data.allMarkdownRemark.group
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="About Me"
          keywords={[`blog`, `photography`, `photos`, `react`, `gatsby`, `nyc`, `new york city`, `brooklyn`]}
        />
        <AboutContainer>
          <div className="image-container">
            <Img fluid={ data.file.childImageSharp.fluid } />
          </div>
          <div className="text">
            <h1>About Me</h1>
          </div>
          <div className="post-body">
            <p>In every photo I have of myself from various trips over the years, I'm almost always holding a camera. (It's actually become a running joke in my family.)</p>
            <p>I love photography for lots of different reasons. I love the feeling of creating something beautiful from what I see around me. I love the clarity that photography brings -- taking the time to compose a shot helps me see and appreciate all the small details that make a place interesting to me. When I'm traveling, it helps me slow down and take in things I might otherwise breeze past. Even when I'm unabashedly "touristing," looking for a creative shot I haven't seen elsewhere before helps me see familiar art and architecture in new ways.</p>
            <p>So in 2019 I decided it was finally time to create a home for all these photos, a place where I could collect my thoughts and jot down a few memories before they fade. I created this site primarily for myself, and to share posts with friends and family, but however you've stumbled across it, I hope you'll enjoy it, too!</p>

            <h2>FAQ</h2>

            <h3>Can I use your photos for my blog/publication/anything?</h3>
            <p>No, you may not use my photos without my prior written permission. You can contact me with questions <a href="https://chrissyhunt.com/#contact">here</a>.</p>

            <h3>What cameras do you use?</h3>
            <p>These days I primarily use a Canon EOS Rebel T6s and an iPhone X. In the past my primary camera was a Canon Digital Rebel XT and I've had a variety of earlier iPhones. I'm currently debating upgrading to something that offers better low-light photography options, perhaps the Sony Alpha 7R II or the Canon EOS 5D Mark IV. (If you have an opinion, let me know!)</p>

            <h3>How did you build this website?</h3>
            <p>I built this site myself using Gatsby and React, and deployed using Netlify. (I'm a professional web developer by day -- you can see more of my work <a href="https://chrissyhunt.com">here</a>. <a href="https://chrissyhunt.com/#contact">Want to hire me for a project?</a>)</p>

            <h3>How can I get in touch?</h3>
            <p><a href="https://chrissyhunt.com/#contact">You can reach me here.</a></p>

          </div>
        </AboutContainer>
        <BlogFooter group={group} chrissy={data.chrissy}/>
      </Layout>
    )
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    chrissy: file(relativePath: { eq: "chrissy-portfolio.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 200) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    file(relativePath: { eq: "chrissy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`